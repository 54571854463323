<template>
  <v-card
    outlined
    class="card-class pa-5 animate__animated animate__fadeIn"
    :class="{
      'mx-10': $vuetify.breakpoint.xsAndDown,
      'exclusive-border': service.exclusive,
    }"
    :disabled="activeService == 1 && service.code !== 212"
    width="100%"
    min-width="270px"
    min-height="380"
  >
    <div class="d-flex justify-center align-end" style="min-height: 56px;">
      <v-img
        contain
        max-height="56"
        max-width="56"
        :src="`/insuranceIcons/${service.icon}.svg`"
      ></v-img>
    </div>
    <v-card-title
      class="
        d-flex
        pb-0
        mt-3
        accent--text
        flex-column
        justify-center
        font-weight-title
        line-height-InsText
        font-size-card-title
        text-center
      "
    >
      <span class="service-title">{{ service.title }}</span>
      <span class="exclusive" v-if="service.exclusive">{{
        $t('petition.exclusiveOffer')
      }}</span>
    </v-card-title>
    <v-card-text
      class="accent--text mt-3 pb-0"
      :class="{ 'd-flex justify-center': $vuetify.breakpoint.mdAndDown }"
    >
      <v-list
        color="white"
        dense
        flat
        class="font-size-card-text line-height-btn"
      >
        <v-list-item
          class="px-2 text-center"
          v-for="(item, i) in service.items"
          :key="i"
          v-show="i < 2"
        >
          <v-list-item-content class="service-list-item" v-text="$t(item.text)">
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <div class="d-flex justify-center align-center flex-column">
      <v-btn
        v-if="service.items && service.items.length > 3"
        @click.stop="dialog = true"
        text
        class="see-all-service text-none mb-10 take-service take-see-all"
      >
        <img class="mr-2" max-width="30" src="/images/share.png" />
        {{ $t('petition.seeAllCoverage') }}</v-btn
      >

      <v-btn
        @click="gotoService(service)"
        dark
        text
        height="50"
        class="text-none primary white--text px-7 take-service"
      >
        {{ $t('getAnOffer') }}
      </v-btn>

      <v-dialog
        scrollable
        width="750"
        v-model="dialog"
        class="rounded-lg"
        :fullscreen="$vuetify.breakpoint.smAndDown"
      >
        <v-card
          class="card-class"
          flat
          :class="{ 'justify-center': $vuetify.breakpoint.smAndDown }"
        >
          <v-card-title
            class="
              full-width
              d-flex
              accent-text
              card-title-width
              justify-space-between
              font-size-card-title
              line-height-InsText
            "
          >
            <span>{{ service.title }}</span>
            <v-btn text fab right absolute small @click="dialog = false">
              <v-icon color="#D0D1D4">mdi-close</v-icon></v-btn
            >
          </v-card-title>
          <v-card-subtitle
            v-if="service.code != 212 && service.code != 209"
            class="accent-text pt-4 font-size-card-text"
            >{{ $t('petition.cardSubheader') }}</v-card-subtitle
          >
          <v-card-subtitle
            v-if="service.code == 209"
            class="accent-text pt-4 font-size-card-text"
            >{{ $t('petition.cardSubheaderBuilding') }}</v-card-subtitle
          >
          <v-card-subtitle
            v-if="service.code == 212"
            class="accent-text pt-4 font-size-card-text"
            >{{ $t('petition.cardSubheaderContent') }}</v-card-subtitle
          >
          <v-card-text>
            <v-list
              dense
              flat
              class="font-size-card-text accent-text line-height-btn"
            >
              <v-list-item v-for="(item, i) in service.items" :key="i">
                <v-list-item-icon class="mr-3">
                  <v-icon color="checks">mdi-check-bold</v-icon>
                </v-list-item-icon>
                <v-list-item-content v-text="$t(item.text)">
                </v-list-item-content>
              </v-list-item> </v-list
          ></v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: 'ServiceCard',
  props: {
    service: {
      type: Object,
      default: null,
    },
    activeService: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters(['petition']),
  },
  methods: {
    ...mapMutations(['setPetition']),
    gotoService({ code, title }) {
      if (!this.activeService || code == 212) {
        this.petition.code = code;
        this.petition.title = title;
        this.setPetition(this.petition);
        this.$router.push({ name: 'Petition' });
      }
    },
  },
  data: () => ({
    dialog: false,
  }),
};
</script>

<style>
.see-all-service {
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 24px;
  text-align: right;
  color: #613CF8 !important;
}

.service-list-item {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 144.5%;
  color: #718592;
  justify-content: center;
  padding: 2px 0 !important;
}

.exclusive-border {
  border: 1px solid #49d69f !important;
}

.take-service {
  position: absolute;
  bottom: 23px;
  width: 87%;
}
</style>
