<template>
  <v-row class="d-flex">
    <v-col
      cols="12"
      class="accent--text font-weight-title font-size-InsTitle line-height-InsText mb-10"
      ><div :class="{ 'pt-10': $vuetify.breakpoint.mdAndUp }">
        {{ $t('homeInsurance.chooseYourProperty') }} <br />
        {{ $t('homeInsurance.needs') }}
      </div></v-col
    >
    <v-row v-if="loading" class="d-flex">
      <v-col lg="3" md="3" sm="12" xs="12">
        <v-skeleton-loader
          class="mx-auto"
          max-width="500"
          width="300"
          type="image"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-else class="d-flex">
      <v-col
        @click="gotoService(item)"
        v-for="(item, index) in homeServices"
        :key="index"
        lg="3"
        md="12"
        sm="12"
        xs="12"
      >
        <ServiceCard :service="item" />
      </v-col>
    </v-row>
    <v-col cols="12">
      <v-btn
        fixed
        bottom
        text
        @click="$router.back()"
        outlined
        color="primary"
        class="text-none px-md-10 pl-2 pr-5 py-6 py-md-5 mb-6 rounded-xl back-btn"
      >
        <v-icon>mdi-chevron-left</v-icon> {{ $t('back') }}</v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import ServiceCard from '@/components/cards/ServiceCard.vue';

export default {
  name: 'HomeInsurance',
  components: { ServiceCard },
  data: () => ({
    dialog: false,
    homeServices: [],
    loading: true,
  }),

  computed: {
    ...mapGetters(['petition']),
  },
  methods: {
    ...mapMutations(['setPetition']),
    gotoService({ code }) {
      this.petition.code = code;
      this.setPetition(this.petition);
      this.$router.push({ name: 'Petition' });
    },
  },
  mounted() {
    this.$axios.get('home-services').then(({ data }) => {
      (this.loading = false), (this.homeServices = data);
    });
  },
};
</script>

<style>
.theme--light.v-btn.v-btn--outlined.v-btn--text.back-btn {
  border-color: #613CF8 !important;
}
</style>
